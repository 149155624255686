import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { formatBytes } from '../utils/formatBytes';
import { ArrowUp, ArrowDown } from 'lucide-react';
import ConclusionPopup from './ConclusionPopup';
const Container = styled.div`
  padding: 20px;
  background-color: #1a0d6c;
  color: #ffffff;
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Title = styled.h1`
  text-align: center;
  color: #8eff01;
  font-family: 'ABCRepro', sans-serif;
`;


const SubH2Title = styled.h2`
  text-align: center;
  color: #8eff01;
  font-family: 'ABCRepro', sans-serif;
`;

const SubTitle = styled.h3`
  text-align: center;
  color: #8eff01;
  font-family: 'ABCRepro', sans-serif;
`;

const TotalsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const TotalBox = styled.div`
  background: #1a1a1a;
  border: 1px solid #333;
  padding: 20px;
  margin: 10px;
  text-align: center;
  flex: 1 1 100px; /* Flex-grow and flex-basis for responsiveness */
`;

const TotalLabel = styled.div`
  color: #8eff01;
  font-size: 18px;
`;

const TotalValue = styled.div`
  font-size: 24px;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    display: table;
  }
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #333;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    display: table-row;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const TableHeader = styled.div`
  background: #1a1a1a;
  color: #8eff01;
  padding: 10px;
  text-align: left;
  border: 1px solid #333;
  display: none;

  @media (min-width: 768px) {
    display: table-cell;
  }
`;

const SortableHeader = styled(TableHeader)`
  cursor: pointer;
  user-select: none;
  align-items: right;
  justify-content: space-between;
`;

const TableCell = styled.div`
  padding: 10px;
  border: 1px solid #333;
  position: relative;

  @media (max-width: 768px) {
    display: block;
    padding-left: 50%;
    text-align: left;
    white-space: nowrap;
    
    &:before {
      content: attr(data-label); /* Use the data-label attribute as content */
      position: absolute;
      left: 10px;
      top: 10px;
      white-space: nowrap;
      font-weight: bold;
      color: #8eff01;
    }
  }

  @media (min-width: 768px) {
    display: table-cell;
  }
`;


const StyledLink = styled(Link)`
  color: #8eff01;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = styled.footer`
  margin-top: auto;
  text-align: center;
  color: #8eff01;
  background-color: #1a1a1a;
  padding: 20px;
`;

const GitHubLink = styled.a`
  color: #8eff01;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Modal = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: 'Courier New', Courier, monospace;
  color: #8eff01;
`;

const ModalContent = styled.div`
  background-color: #1a1a1a;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #333;
  width: 90%;
  max-width: 600px;
  text-align: center;
`;

const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

const Button = styled.button`
  background-color: #8eff01;
  color: #1a0d6c;
  border: none;
  padding: 10px 20px;
  margin: 20px 0;
  cursor: pointer;
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;

  &:hover {
    background-color: #76c700;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  max-width: 90%;
  padding: 10px;
  background-color: #333;
  color: #8eff01;
  border-radius: 5px;
  border: 1px solid #333;
`;

const TwitterCTA = styled.div`
  position: fixed; // Make the button fixed
  bottom: 20px; // Distance from the bottom
  right: 20px; // Distance from the right
  z-index: 1000; // Ensure it appears above other content
`;

const TwitterLink = styled.button`
  background-color: #8eff01;
  color: #1a0d6c;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #76c700;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #1a1a1a;
  border-radius: 20px;
  margin: 20px 0;
`;

const ProgressBarFill = styled.div`
  height: 30px;
  background-color: #8eff01;
  border-radius: 20px;
  width: ${props => props.percentage}%;
  transition: width 0.5s ease-in-out;
  position: relative;
`;

const ProgressBarText = styled.div`
  color: #1a0d6c;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  .mobile-only {
    display: none; // Hide by default
  }

  .desktop-only {
    display: block; // Show by default
  }

  @media (max-width: 768px) {
    .mobile-only {
      display: block; // Show on mobile
    }

    .desktop-only {
      display: none; // Hide on mobile
    }
  }
`;

const CopyButton = styled.button`
  background-color: #1a0d6c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #76c700;
  }
`;

const ActionButton = styled(Link)`
  position: fixed;
  bottom: 20px;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    background-color: #76c700;
  }
`;

const InscribeButton = styled(ActionButton)`
  right: 20px;
  background-color: #8eff01;
  color: #1a0d6c;
`;
const ViewIdeasButton = styled(ActionButton)`
  right: 20px; // Adjust this value as needed to space it from the Inscribe button
  background-color: #1a0d6c;
  color: #8eff01;
  border: 2px solid #8eff01;
`;

const Dashboard = () => {
  const [chains, setChains] = useState([]);
  const [totalDataPosted, setTotalDataPosted] = useState(0);
  const [throughput, setThroughput] = useState(0);
  const [tps, setTps] = useState(0);
  const [chainTps, setChainTps] = useState([]); // New state for chain TPS
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState('');
  const [showTweetButton, setShowTweetButton] = useState(false);
  const [apiResult, setApiResult] = useState(null);
  const [tweetText, setTweetText] = useState('');
  const [sentimentResponse, setSentimentResponse] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'dataPosted', direction: 'descending' });
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [showConclusionPopup, setShowConclusionPopup] = useState(true);

  const DATA_ENDPOINT = "https://data-api.eigenda.wtf";
  const USER_DATA_ENDPOINT = "https://api-interact.eigenda.wtf";

  useEffect(() => {
    const cachedChains = localStorage.getItem('chains');
    const cachedTotalDataPosted = localStorage.getItem('totalDataPosted');
    const cachedTps = localStorage.getItem('tps');
    const cachedThroughput = localStorage.getItem('throughput');

    if (cachedChains) setChains(JSON.parse(cachedChains));
    if (cachedTotalDataPosted) setTotalDataPosted(JSON.parse(cachedTotalDataPosted));
    if (cachedTps) setTps(parseFloat(cachedTps));
    if (cachedThroughput) setThroughput(parseFloat(cachedThroughput));
  }, []);

  useEffect(() => {
    const fetchChains = async () => {
      try {
        const response = await axios.get(`${DATA_ENDPOINT}/chain_info`);
        setChains(response.data);
        localStorage.setItem('chains', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error fetching chains:', error);
      }
    };

    const fetchTotalDataPosted = async () => {
      try {
        const response = await axios.get(`${DATA_ENDPOINT}/total_data_posted`);
        setTotalDataPosted(response.data.total_data_posted);
        localStorage.setItem('totalDataPosted', JSON.stringify(response.data.total_data_posted));
      } catch (error) {
        console.error('Error fetching total data posted:', error);
      }
    };

    const fetchTps = async () => {
      try {
        const cachedTps = localStorage.getItem('tps');
        const cachedChainTps = localStorage.getItem('chainTps');

        if (cachedTps) {
          setTps(JSON.parse(cachedTps));
        }

        if (cachedChainTps) {
          setChainTps(JSON.parse(cachedChainTps));
        } 
          const response = await axios.get('https://1isz2va3vg.execute-api.eu-north-1.amazonaws.com/tps', {
            headers: { 'Access-Control-Allow-Origin': '*' }
          })
          setTps(Number(response.data.totalTps).toFixed(2));
          setChainTps(response.data.networkBreakdown); // Set chain TPS data
          localStorage.setItem('tps', Number(response.data.totalTps).toFixed(2));
          localStorage.setItem('chainTps', JSON.stringify(response.data.networkBreakdown)); // Cache chain TPS
        
      } catch (error) {
        console.error('Error fetching TPS:', error);
      }
    };

    const fetchThroughput = async () => {
      try {
        const response = await axios.get(`${DATA_ENDPOINT}/throughput`);
        console.log(response.data.throughput)
        setThroughput(response.data.throughput);
        localStorage.setItem('throughput', response.data.throughput);
      } catch (error) {
        console.error('Error fetching throughput:', error);
      }
    };

    fetchChains();
    fetchTotalDataPosted();
    fetchTps();
    fetchThroughput();

    const interval = setInterval(() => {
      fetchChains();
      fetchTotalDataPosted();
      fetchTps();
      fetchThroughput();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleCloseConclusionPopup = () => {
    setShowConclusionPopup(false);
  };

  const fetchChains = useCallback(async () => {
    try {
      const response = await axios.get(`${DATA_ENDPOINT}/chain_info`);
      const updatedChains = response.data;
      console.log(updatedChains)
      setChains(updatedChains);
      localStorage.setItem('chains', JSON.stringify(updatedChains));
    } catch (error) {
      console.error('Error fetching chains:', error);
    }
  }, []);

  useEffect(() => {
    fetchChains();
    const interval = setInterval(fetchChains, 2000); // Run every 2 seconds

    return () => clearInterval(interval);
  }, [fetchChains]);

  const sortChains = useCallback((chains, sortConfig) => {
    return [...chains].sort((a, b) => {
      if (sortConfig.key === 'dataPosted') {
        // Sort numerically for dataPosted
        return sortConfig.direction === 'ascending' 
          ? a[4] - b[4] 
          : b[4] - a[4];
      } else if (sortConfig.key === 'throughput') {
        // Sort numerically for throughput
        return sortConfig.direction === 'ascending' 
          ? a[3] - b[3] 
          : b[3] - a[3];
      }
      // Add more conditions here for other numeric fields if needed
      // For non-numeric fields, keep the existing logic:
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }, []);

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'descending' ? 'ascending' : 'descending'
    }));
  };

  const sortedChains = sortChains(chains, sortConfig);

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <ArrowUp size={16} /> : <ArrowDown size={16} />;
    }
    return null;
  };

  const handlepostNow = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowTweetButton(false);
  };

  const handlepostItBro = async () => {
    setIsLoading(true); // Set loading state to true
    setApiResult(null);
    try {
      // First, call the AI sentiment check API with the detailed prompt
      const sentimentResponse = await axios.post('https://0x768da699e7b40d6fa4660afefa33ef6ccc45749a.us.gaianet.network/v1/chat/completions', {
        messages: [
          { role: "system", content: `You are an AI assistant for a web application called All Chains and Ideas Fit on EigenDA, which allows users to inscribe ideas onto a EigenDA. EigenDA has the highest throughput data availability provider in the world with horizontal scaling upto 15mb/s. User will write their ideas and agent will evaluate it based on the following instruction. In the stunt, we're dumping all the active raw block data on EigenDA and it still works and has capacity. We believe in Open Innovation. Your task is to review the text submitted by users and determine if it's suitable for inscription. IT SHOULD ONLY ACCEPT IDEAS. NOT RANDOM TEXT.You should respond in a JSON format with two fields: "postable" and "reply".
Instructions:
Analyze the given text for appropriateness, considering factors such as offensive language, hate speech, explicit content, or potential copyright infringement.
Determine if the text is suitable for public inscription.
Craft a brief, humorous, and fun reply about the user's idea. The reply should be light-hearted and entertaining, but never offensive.
If the text is not suitable, your reply should gently explain why, using humor to soften the message.
Your response should explicitly follow this JSON format, no other response is allowed. VERY IMPORTANT:gDon't forget to start and close the JSON with { and }:
{
"postable": "yes" or "no",
"reply": "Your humorous and fun reply goes here"
}
Example 1 (Suitable text):
User input: "I think pizza should be considered a vegetable because of the tomato sauce."
Your response:
{
"postable": "yes",
"reply": "Ah, a true pizza philosopher! Your cheesy logic is so convincing, I'm tempted to start a veggie garden full of pepperoni. This idea is hot and ready for inscription!"
}
Example 2 (Unsuitable text):
User input: "I hate everyone and wish they would disappear."
Your response:
{
"postable": "no",
"reply": "Whoa there, Thanos! While your snap-py idea is certainly... impactful, we're aiming for less universe-altering inscriptions. How about inscribing a joke instead? It'll make people disappear... into fits of laughter!"
}
Remember, your replies should always be fun and humorous, never mean-spirited or offensive, even when rejecting an idea. Aim to make the user smile, regardless of whether their idea is postable or not.` },
          { role: "user", content: text } // Use the input text for sentiment analysis
        ]
      });

      console.log(sentimentResponse.data)
      // Parse the response string to JSON format
      const responseContent = JSON.parse(sentimentResponse.data.choices[0].message.content);
      const { postable, reply } = responseContent; // Extract postable and reply from the parsed response

      setSentimentResponse({ postable, reply });

      if (postable === "yes") {
        // If the AI says it's okay, proceed with the inscription
        const formData = new FormData();
        formData.append('text', text);
        const response = await axios.post(`${USER_DATA_ENDPOINT}/upload-text/`, 
          formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        setApiResult(response.data);
        setShowTweetButton(true);

        const tweetText = `I just inscribed my idea. #AllIdeasFitOnEigenDA

        "${text}"

  https://x.com/sreeramkannan/status/1834265535598788793`;
        setTweetText(tweetText);
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`);
      } else {
        // If not postable, show the AI's reply
        setSentimentResponse({ postable, reply });
      }
    } catch (error) {
      console.error('Error posting data:', error);
    } finally {
      setIsLoading(false); // Reset loading state after processing
    }
  };

  const handleCopy = () => {
    if (apiResult && apiResult.request_id) {
      navigator.clipboard.writeText(apiResult.request_id)
        .then(() => {
          alert('Request ID copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  const CapacityProgressBar = ({ throughput }) => {
    const maxCapacity = 15; // 15 MB/s
    const percentage = Math.min((throughput / maxCapacity) * 100, 100);
  
    return (
      <ProgressBarContainer>
        <ProgressBarFill percentage={percentage}>
          <ProgressBarText>
            {/* Show only percentage on mobile */}
            <span className="mobile-only">{percentage.toFixed(2)}%</span>
            <span className="desktop-only">{percentage.toFixed(2)}% ({throughput.toFixed(2)} / {maxCapacity} MB/s)</span>
          </ProgressBarText>
        </ProgressBarFill>
      </ProgressBarContainer>
    );
  };

  return (
    <Container>
      <Link to="/" style={{ textDecoration: 'none', color: '#8eff01' }}>
      <Title>
        All Chains Fit on <img src="/eigenda.png" alt="EigenDA" style={{ height: '25px' }} />
      </Title>
      </Link>
      <SubH2Title>We're posting every new block from every chain on EigenDA Mainnet</SubH2Title>
      {/* <SubH2Title>EigenDA is removing all constraints to convert cloud to crypto</SubH2Title> */}
      <TotalsContainer>
        <TotalBox>
          <TotalLabel>Throughput</TotalLabel>
          <TotalValue><CountUp start={(throughput/1024/1024)} end={(throughput/1024/1024)} duration={0.4} separator="," decimals={2} decimal="." /> MB/s</TotalValue>
        </TotalBox>
        {tps ? (
        <TotalBox>
          <TotalLabel>TPS</TotalLabel>
          <TotalValue><CountUp start={tps} end={tps} duration={0.4} separator="," decimals={2} decimal="." /></TotalValue>
        </TotalBox>
        ) : null}
        <TotalBox>
          <TotalLabel>Data Posted</TotalLabel>
          <TotalValue><CountUp start={(totalDataPosted)/1024/1024} end={(totalDataPosted)/1024/1024} duration={0.4} separator="," decimals={2} decimal="." suffix={` MB`} /></TotalValue>
        </TotalBox>
      </TotalsContainer>
      <CapacityProgressBar throughput={throughput / 1024 / 1024} />
      <Table>
        <div style={{ display: 'table-row' }}>
          <TableHeader>Chain Name</TableHeader>
          {/* <TableHeader>Stack</TableHeader>
          <TableHeader>Settlement Chain</TableHeader> */}
          <SortableHeader onClick={() => handleSort('throughput')}>
            Throughput
            {getSortIcon('throughput')}
          </SortableHeader>
          <SortableHeader onClick={() => handleSort('dataPosted')}>
            Data Posted
            {getSortIcon('dataPosted')}
          </SortableHeader>
          {chainTps ? <TableHeader>TPS</TableHeader> : null}
          <TableHeader>Action</TableHeader>
        </div>
        {sortedChains.map((chain) => {
          const chainTpsData = chainTps.find(c => c.name === chain[0]); // Find TPS for the current chain
          return (
            <TableRow key={chain.id} style={{ backgroundColor: '#0e0e0e' }}>
              <TableCell data-label="Chain Name">{chain[0]}</TableCell>
              {/* <TableCell data-label="Stack">{chain[1]}</TableCell>
              <TableCell data-label="Settlement Chain">{chain[2]}</TableCell> */}
              <TableCell data-label="Throughput">
                {chain[0] === "Bitcoin" 
                  ? formatBytes(chain[3] / 60) 
                  : formatBytes(chain[3])}/s
              </TableCell>              
              <TableCell data-label="Data Posted">{formatBytes(chain[4])}</TableCell>
              <TableCell data-label="TPS">{chainTpsData ? chainTpsData.tps.toFixed(0) : null}</TableCell> {/* Display TPS for the chain */}
              <TableCell data-label="Action">
                <StyledLink to={`https://eigenda.wtf/${chain[0]}/blocks`}>View Blocks</StyledLink>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>
      <Footer>
      🚀 Built With Love at Eigen Labs 🚀
      <br /> <br />
      <GitHubLink href="https://github.com/Layr-Labs/da-stunt" target="_blank" rel="noopener noreferrer">
        View on GitHub
      </GitHubLink>
      <br />
      <GitHubLink href="https://x.com/eigen_da" target="_blank" rel="noopener noreferrer">
        Follow on Twitter
      </GitHubLink>
      <br />
      <GitHubLink href="https://docs.eigenda.xyz" target="_blank" rel="noopener noreferrer">
        Learn More about EigenDA
      </GitHubLink>
      </Footer>
      <Modal show={showModal}>
        <ModalContent>
          <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
          <h2>All Ideas Fit on EigenDA</h2>
          <p>Now that blobspace is not a constraint, what ideas do you want built on Eigen?</p>
          <Textarea
            value={text}
            onChange={(event) => {
              if (event.target.value.length <= 2000) {
                setText(event.target.value);
              }
            }}
            placeholder="Type something here..."
            rows="10"
            wrap="soft" // Enable text wrapping
          />
          <Button onClick={handlepostItBro}>Inscribe Now</Button>
          {isLoading && <p>Message is being sent and is processing...</p>} {/* Loading message */}
          {sentimentResponse && (
            <p>{sentimentResponse.reply}</p>
          )}
          {apiResult && (
            <div>
              <p>Request ID: {`${apiResult.request_id.slice(0, 5)}...${apiResult.request_id.slice(-5)}`}</p>
              <CopyButton onClick={handleCopy}>Copy</CopyButton>
            </div>
          )}
          {showTweetButton && (
            <Button onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`)}>Tweet Now</Button>
          )}
        </ModalContent>
      </Modal>
      
      <ViewIdeasButton to="/ideas">View Ideas</ViewIdeasButton>
      {showConclusionPopup && <ConclusionPopup onClose={handleCloseConclusionPopup} />}
      {/* <InscribeButton onClick={handlepostNow}>Inscribe Now</InscribeButton> */}
    </Container>
  );
};

export default Dashboard;
