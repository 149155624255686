import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  padding: 20px;
  background-color: #1a0d6c;
  color: #ffffff;
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Title = styled.h1`
  text-align: center;
  color: #8eff01;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    display: table;
  }
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #333;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    display: table-row;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const TableHeader = styled.div`
  background: #1a1a1a;
  color: #8eff01;
  padding: 10px;
  text-align: left;
  border: 1px solid #333;
  display: none;

  @media (min-width: 768px) {
    display: table-cell;
  }
`;

const TableCell = styled.div`
  padding: 10px;
  border: 1px solid #333;
  position: relative;

  @media (max-width: 768px) {
    display: block;
    padding-left: 50%;
    text-align: left;
    white-space: nowrap;

    &:before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      top: 10px;
      white-space: nowrap;
      font-weight: bold;
      color: #8eff01;
    }
  }

  @media (min-width: 768px) {
    display: table-cell;
  }
`;

const ViewButton = styled.button`
  background-color: #8eff01;
  color: #1a0d6c;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #76c700;
  }
`;

const ActionButton = styled(Link)`
  position: fixed;
  bottom: 20px;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    background-color: #76c700;
  }
`;

const InscribeButton = styled(ActionButton)`
  right: 20px;
  background-color: #8eff01;
  color: #1a0d6c;
`;

const Modal = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  font-family: 'Courier New', Courier, monospace;
  color: #8eff01;
`;

const ModalContent = styled.div`
  background-color: #1a1a1a;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #333;
  width: 90%;
  max-width: 600px;
  text-align: center;
`;

const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`;

const Button = styled.button`
  background-color: #8eff01;
  color: #1a0d6c;
  border: none;
  padding: 10px 20px;
  margin: 20px 0;
  cursor: pointer;
  font-size: 20px;
  font-family: 'Courier New', Courier, monospace;

  &:hover {
    background-color: #76c700;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin: 10px 10px;
  background-color: #333;
  color: #8eff01;
  border-radius: 5px;
  border: 1px solid #333;
  wrap: "soft" // Enable text wrapping
`;

const CopyButton = styled.button`
  background-color: #8eff01;
  color: #1a0d6c;
  border: none;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #76c700;
  }
`;

const UserPage = () => {
  const [responses, setResponses] = useState([]);
  const [text, setText] = useState('');
  const [sentimentResponse, setSentimentResponse] = useState('');
  const [showTweetButton, setShowTweetButton] = useState(false);
  const [tweetText, setTweetText] = useState('');
  const [apiResult, setApiResult] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const USER_DATA_ENDPOINT = "https://api-interact.eigenda.wtf";

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        // Replace with your actual API endpoint
        const responsesResponse = await axios.get('https://data-api.eigenda.wtf/TextUploads/blocks');
        setResponses(responsesResponse.data);
      } catch (error) {
        console.error('Error fetching responses:', error);
      }
    };

    fetchResponses();

    const interval = setInterval(fetchResponses, 10000); // Fetch every 10 seconds

    return () => clearInterval(interval);
  }, []);

  const handlepostItBro = async () => {
    setIsLoading(true); // Set loading state to true
    setApiResult(null);
    try {
      // First, call the AI sentiment check API with the detailed prompt
      const sentimentResponse = await axios.post('https://0x768da699e7b40d6fa4660afefa33ef6ccc45749a.us.gaianet.network/v1/chat/completions', {
        messages: [
          { role: "system", content: `You are an AI assistant for a web application called All Chains and Ideas Fit on EigenDA, which allows users to inscribe ideas onto a EigenDA. EigenDA has the highest throughput data availability provider in the world with horizontal scaling upto 15mb/s. User will write their ideas and agent will evaluate it based on the following instruction. In the stunt, we're dumping all the active raw block data on EigenDA and it still works and has capacity. We believe in Open Innovation. Your task is to review the text submitted by users and determine if it's suitable for inscription. IT SHOULD ONLY ACCEPT IDEAS. NOT RANDOM TEXT.You should respond in a JSON format with two fields: "postable" and "reply".
Instructions:
Analyze the given text for appropriateness, considering factors such as offensive language, hate speech, explicit content, or potential copyright infringement.
Determine if the text is suitable for public inscription.
Craft a brief, humorous, and fun reply about the user's idea. The reply should be light-hearted and entertaining, but never offensive.
If the text is not suitable, your reply should gently explain why, using humor to soften the message.
Your response should explicitly follow this JSON format, no other response is allowed. Don't forget to start and close the JSON with { and }:
{
"postable": "yes" or "no",
"reply": "Your humorous and fun reply goes here"
}
Example 1 (Suitable text):
User input: "I think pizza should be considered a vegetable because of the tomato sauce."
Your response:
{
"postable": "yes",
"reply": "Ah, a true pizza philosopher! Your cheesy logic is so convincing, I'm tempted to start a veggie garden full of pepperoni. This idea is hot and ready for inscription!"
}
Example 2 (Unsuitable text):
User input: "I hate everyone and wish they would disappear."
Your response:
{
"postable": "no",
"reply": "Whoa there, Thanos! While your snap-py idea is certainly... impactful, we're aiming for less universe-altering inscriptions. How about inscribing a joke instead? It'll make people disappear... into fits of laughter!"
}
Remember, your replies should always be fun and humorous, never mean-spirited or offensive, even when rejecting an idea. Aim to make the user smile, regardless of whether their idea is postable or not.` },
          { role: "user", content: text } // Use the input text for sentiment analysis
        ]
    });

      console.log(sentimentResponse.data)
      // Parse the response string to JSON format
      const responseContent = JSON.parse(sentimentResponse.data.choices[0].message.content);
      const { postable, reply } = responseContent; // Extract postable and reply from the parsed response

      setSentimentResponse({ postable, reply });

      if (postable === "yes") {
        // If the AI says it's okay, proceed with the inscription
        const formData = new FormData();
        formData.append('text', text);
        const response = await axios.post(`${USER_DATA_ENDPOINT}/upload-text/`, 
          formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        setApiResult(response.data);
        setShowTweetButton(true);

        const tweetText = `I just inscribed my idea on EigenDA. 

        "${text}"

  https://x.com/sreeramkannan/status/1834265535598788793`;
        setTweetText(tweetText);
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`);
      } else {
        // If not postable, show the AI's reply
        setSentimentResponse({ postable, reply });
      }
    } catch (error) {
      console.error('Error posting data:', error);
    } finally {
      setIsLoading(false); // Reset loading state after processing
    }
  };

  const handlepostNow = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowTweetButton(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(apiResult.request_id); // Copy the request ID to clipboard
    alert('Request ID copied to clipboard!'); // Optional: Notify the user
  };

  return (
    <Container>
      <Link to="/" style={{ textDecoration: 'none', color: '#8eff01' }}>
        <Title>
          All Ideas on <img src="/eigenda.png" alt="EigenDA" style={{ height: '25px' }} />
        </Title>
      </Link>
      {/* <InscribeButton onClick={handlepostNow}>Inscribe Now</InscribeButton> */}
      <Table>
        <div style={{ display: 'table-row' }}>
          <TableHeader>Sr Number</TableHeader>
          <TableHeader>Idea</TableHeader>
          <TableHeader>Request ID</TableHeader>
          <TableHeader>Action</TableHeader>
        </div>
        {responses.map((response, index) => (
          <TableRow style={{ backgroundColor: '#0e0e0e' }} key={response[0]}>
            <TableCell data-label="Serial Number">{response[0]}</TableCell>
            <TableCell data-label="Idea" style={{ whiteSpace: 'normal' }}> {/* Allow text to wrap */}
              {response[1]}
            </TableCell>
            <TableCell data-label="Request ID">
              {`${response[2].slice(0, 5)}...${response[2].slice(-5)}`}
            </TableCell>
            <TableCell data-label="Action">
              <ViewButton onClick={() => window.open(`https://blobs.eigenda.xyz/blobs/${response[2]}`, '_blank')}>
                View Blob
              </ViewButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {isLoading && <p>Message is being sent and is processing...</p>} {/* Loading message */}
      <Modal show={showModal}>
        <ModalContent>
          <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
          <h2>All Ideas Fit on EigenDA</h2>
          <p>Now that blobspace is not a constraint, what ideas do you want built on Eigen?</p>
          <Textarea
            value={text}
            onChange={(event) => {
              if (event.target.value.length <= 2000) {
                setText(event.target.value);
              }
            }}
            placeholder="Type something here..."
            rows="10"
            wrap="soft" // Enable text wrapping
          />
          <Button onClick={handlepostItBro}>Inscribe Now</Button>
          {isLoading && <p>Message is being sent and is processing...</p>} {/* Loading message */}
          {sentimentResponse && (
            <p>{sentimentResponse.reply}</p>
          )}
          {apiResult && (
            <div>
              <p>Request ID: {`${apiResult.request_id.slice(0, 5)}...${apiResult.request_id.slice(-5)}`}</p>
              <CopyButton onClick={handleCopy}>Copy</CopyButton>
            </div>
          )}
          {showTweetButton && (
            <Button onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`)}>Tweet Now</Button>
          )}
        </ModalContent>
      </Modal>
      
      {/* <InscribeButton onClick={handlepostNow}>Inscribe Now</InscribeButton> */}

    </Container>
  );
};

export default UserPage;