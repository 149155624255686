// src/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';
import ABCReproMedium from './fonts/ABCRepro-Medium.otf';
import ABCReproVariable from './fonts/ABCReproVariable.ttf';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ABCRepro';
    src: url(${ABCReproVariable}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Courier New', Courier, monospace;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'ABCRepro', 'Courier New', Courier, monospace;
    color: ${({ theme }) => theme.colors.primary};
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th, td {
    padding: 10px;
    border: 1px solid #333;
  }

  th {
    background: #1a1a1a;
    color: ${({ theme }) => theme.colors.primary};
  }

  tr:nth-child(even) {
    background: #2a2a2a;
  }

  tr:hover {
    background: #3a3a3a;
  }
`;

export default GlobalStyles;
