// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './GlobalStyles';
import { theme } from './theme';
import Dashboard from './components/Dashboard';
import BlockPage from './components/BlockPage';
import UserPage from './components/UserPage';
import { Analytics } from "@vercel/analytics/react"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/:chainId/blocks" element={<BlockPage />} />
          <Route path="/ideas" element={<UserPage />} />
        </Routes>
      </Router>
      <Analytics />
    </ThemeProvider>
  );
};

export default App;
