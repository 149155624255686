import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { formatBytes } from '../utils/formatBytes';
import { Link } from 'react-router-dom'; // Import Link

const Container = styled.div`
  padding: 20px;
  background-color: #1a0d6c;
  color: #ffffff;
  font-family: 'Courier New', Courier, monospace;
`;

const Title = styled.h1`
  text-align: center;
  color: #8eff01;
`;

const SubTitle = styled.h2`
  text-align: center;
  color: #8eff01;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  @media (min-width: 768px) {
    display: table;
  }
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #333;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    display: table-row;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

const TableHeader = styled.div`
  background: #1a1a1a;
  color: #8eff01;
  padding: 10px;
  text-align: left;
  border: 1px solid #333;
  display: none;

  @media (min-width: 768px) {
    display: table-cell;
  }
`;

const TableCell = styled.div`
  padding: 10px;
  border: 1px solid #333;

  @media (min-width: 768px) {
    display: table-cell;
  }
`;

const ViewButton = styled.button`
  background-color: #8eff01;
  color: #1a0d6c;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #76c700;
  }
`;

const ToggleContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin: 15px 0;
`;

const ToggleInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const ToggleSlider = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 30px;
  background: ${props => props.checked ? '#8eff01' : '#333'};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: ${props => props.checked ? 'calc(100% - 2px)' : '2px'};
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
    transition: 0.2s;
    transform: ${props => props.checked ? 'translateX(-100%)' : 'translateX(0)'};
  }
`;

const ToggleLabel = styled.span`
  color: #8eff01;
  font-weight: bold;
  margin-left: 10px;
`;

const BlockPage = () => {
  const { chainId } = useParams();
  const [blocks, setBlocks] = useState([]);
  const [chainData, setChainData] = useState();
  const [showConfirmed, setShowConfirmed] = useState(false); 

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        if (showConfirmed === true) {
          const blocksResponse = await axios.get(`https://data-api.eigenda.wtf/${chainId}/confirmed_blocks`);
          const fetchedBlocks = blocksResponse.data;
          setBlocks(fetchedBlocks);
          localStorage.setItem(`blocks_${chainId}`, JSON.stringify(fetchedBlocks));
        } else {
          const blocksResponse = await axios.get(`https://data-api.eigenda.wtf/${chainId}/blocks`);
          const fetchedBlocks = blocksResponse.data;
          setBlocks(fetchedBlocks);
          localStorage.setItem(`blocks_${chainId}`, JSON.stringify(fetchedBlocks));
        }
      } catch (error) {
        console.error('Error fetching blocks:', error);
      }
    };

    // Set initial state from cache on mount
    const cachedBlocks = localStorage.getItem(`blocks_${chainId}`);
    const cachedChainData = localStorage.getItem(`chainData_${chainId}`);

    if (cachedBlocks) setBlocks(JSON.parse(cachedBlocks));
    if (cachedChainData) setChainData(JSON.parse(cachedChainData));

    fetchBlocks();

    let intervalPeriod = 5000;
    if (chainId == "Solana") {
      intervalPeriod = 1000;
    } 

    const interval = setInterval(() => {
      fetchBlocks();
    }, intervalPeriod); // Adjusted to 10 seconds to reduce load

    return () => clearInterval(interval);
  }, [chainId, showConfirmed]);

  return (
    <Container>
        <Title>
          <Link to="/" style={{ textDecoration: 'none', color: '#8eff01' }}> {/* Link to homepage */}
            {chainId} Fits on <img src="/eigenda.png" alt="EigenDA" style={{ height: '25px' }} />
          </Link>
        </Title>
      
      <ToggleContainer>
        <ToggleInput
          type="checkbox"
          checked={showConfirmed}
          onChange={() => setShowConfirmed(!showConfirmed)}
          id="confirmed-toggle"
        />
        <ToggleSlider checked={showConfirmed} />
        <ToggleLabel htmlFor="confirmed-toggle">Confirmed Blobs Only</ToggleLabel>
      </ToggleContainer>

      <Table>
        <div style={{ display: 'table-row' }}>
          <TableHeader>Block Number</TableHeader>
          <TableHeader>Block Size</TableHeader>
          <TableHeader>Time</TableHeader>
          <TableHeader>Request ID</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>View Blob</TableHeader>
        </div>
        {blocks.map((block) => (
          <TableRow style={{ backgroundColor: '#0e0e0e' }} key={block[1]}>
            <TableCell>{block[1]}</TableCell>
            <TableCell>{formatBytes(block[2])}</TableCell>
            <TableCell>{new Date(block[4]).toLocaleString() || 'N/A'}</TableCell>
            <TableCell>
              {block[3].slice(0, 5)}...{block[3].slice(-5)}
            </TableCell>
            <TableCell>
              {new Date(block[4]).getTime() > Date.now() - 5 * 60 * 1000 ? "Processing" : "Confirmed"}
            </TableCell>
            <TableCell>
              {/* Additional content can go here */}
              <ViewButton onClick={() => window.open(`https://blobs.eigenda.xyz/blobs/${block[3]}`, '_blank')}>
                View Blob
              </ViewButton> {/* New TableCell with View Blob button */}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Container>
  );
};

export default BlockPage;
