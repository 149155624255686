import React, { useState } from 'react';
import styled from 'styled-components';

const StopNoticePopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  border: 2px solid #8eff01;
  padding: 20px;
  z-index: 1000;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  color: #ffffff;
  font-family: 'Courier New', Courier, monospace;
`;

const ClosePopupButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #8eff01;
  font-size: 20px;
  cursor: pointer;
`;

const Title = styled.h2`
  color: #8eff01;
  text-align: center;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const ResultsList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ResultItem = styled.li`
  margin-bottom: 10px;
`;

const Highlight = styled.span`
  color: #8eff01;
`;

const ConclusionPopup = ({ onClose }) => {
  return (
    <StopNoticePopup>
      <ClosePopupButton onClick={onClose}>&times;</ClosePopupButton>
      <Title>EigenDA Throughput Demonstration Concluded</Title>
      <Content>
        <p>As we conclude our EigenDA throughput demonstration, "All Chains Fit on EigenDA" over September, we're thrilled with the results:</p>
        <ResultsList>
          <ResultItem>- <Highlight>15+ million</Highlight> blobs posted</ResultItem>
          <ResultItem>- <Highlight>20+ TB</Highlight> of data processed</ResultItem>
          <ResultItem>- <Highlight>385+</Highlight> inscriptions</ResultItem>
          <ResultItem>- <Highlight>1 million+</Highlight> views</ResultItem>
        </ResultsList>
        <p>EigenDA proved its capacity to handle multiple chains simultaneously, showcasing unparalleled scalability in crypto.</p>
        <p>This wasn't just a lab experiment. We ran this on the mainnet in September, showing real-world capability. It's not "trust me, bro" - it's "see for yourself."</p>
        <p>What's next? The potential is limitless. Whether you're building a high-throughput dApp, a data-intensive blockchain, or exploring new frontiers, EigenDA is ready.</p>
        <p>Thank you, everyone, for participating in this experiment. More to come, upwards and onwards.</p>
        <p><Highlight>Be Ready For Hacktober!!!</Highlight></p>
      </Content>
    </StopNoticePopup>
  );
};

export default ConclusionPopup;